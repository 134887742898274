import Vue from 'vue'
import VueRouter from 'vue-router'
import routes from './routes'
import store from '@/store'
Vue.use(VueRouter)
const router = new VueRouter({
  mode: 'history',
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 }
  }
})
// 路由切词函数
function setDocumentTitle(to) {
  if (to.meta && to.meta.subtitle) {
    document.title = to.meta.subtitle; // 设置页面标题
  }
}
router.beforeEach((to, form, next) => {
  setDocumentTitle(to)
  store.commit('changeOpacity', to.meta.opacityMenu)
  next()
})
export default router