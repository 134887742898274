<template>
  <div class="active-side" v-if="visible">
    <ul>
      <li>
        <img class="icon" src="../../assets/image/home/sidebar4.png" alt="" />
        <div class="icon-text">微信</div>
        <div class="over-img">
          <img src="../../assets/image/home/code4.png" alt="" />
        </div>
      </li>
      <li>
        <img class="icon" src="../../assets/image/home/sidebar3.png" alt="" />
        <div class="icon-text">微信公众号</div>
        <div class="over-img">
          <img src="../../assets/image/home/code3.png" alt="" />
        </div>
      </li>
      <li>
        <img class="icon" src="../../assets/image/home/sidebar2.png" alt="" />
        <div class="icon-text">微信视频号</div>
        <div class="over-img">
          <img src="../../assets/image/home/code2.png" alt="" />
        </div>
      </li>
      <li class="border-0">
        <img class="icon" src="../../assets/image/home/sidebar1.png" alt="" />
        <div class="icon-text">抖音</div>
        <div class="over-img">
          <img src="../../assets/image/home/code1.png" alt="" />
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'ActionSidePc',
  data () {
    return {
      visible: true
    }
  },
  methods: {
    getPopupContainer (trigger) {
      return trigger.parentElement
    }
  }
}
</script>

<style lang="less" scoped>
.active-side {
  position: fixed;
  width: 72px;
  height: 270px;
  bottom: 66px;
  right: 10px;
  background: url('../../assets/image/home/sidebar.png') no-repeat;
  background-size: contain;
  text-align: center;
  .info {
    width: 240px;
  }
  .close {
    margin-top: 4px;
    width: 30px;
    cursor: pointer;
  }
  ul {
    width: 100%;
    height: 100%;
    padding: 0;
    list-style-type: none;

    li {
      text-align: center;
      width: 100%;
      height: 25%;
      font-weight: 500;
      font-size: 10px;
      color: #ffffff;
      line-height: 14px;
      border-bottom: 1px solid rgba(255, 255, 255, 0.35);
      img {
        width: 28px;
        height: 28px;
        margin: 15px auto 2px;
      }
      .over-img {
        width: 210px;
        height: 246px;
        position: absolute;
        bottom: 12px;
        right: 60px;
        display: none;
        img {
          width: 100%;
          height: 100%;
          margin: 0;
        }
      }

      &:active,
      &:hover {
        background-color: rgba(255, 255, 255, 0.2);
        .over-img {
          display: block;
        }
      }
    }
    .border-0 {
      border: 0;
    }
  }
  .module-tooltip {
    width: 160px;
    height: 200px;
    background: #fff;
    border-radius: 10px;
  }
}
</style>
