export default [
  {
    name: 'home',
    path: '/',
    component: () => import('../views/HomePage'),
    meta: {
      title: '首页',
      subtitle: '寓信科技_公寓管理系统引领者-寓信',
      opacityMenu: true
    }
  }, {
    name: 'product',
    path: '/product',
    component: () => import('../views/ProductService'),
    meta: {
      title: '产品服务',
      subtitle: '寓信长租公寓管理系统_智慧公寓资产管理平台-寓信',
      opacityMenu: true
    }
  }, {
    name: 'deviceLock',
    path: '/device/lock',
    component: () => import('../views/device'),
    meta: {
      title: '智能门锁',
      subtitle: '流动人口和出租房屋管理平台-寓信',
      opacityMenu: true
    }
  }, {
    name: 'deviceMeter',
    path: '/device/meter',
    component: () => import('../views/device'),
    meta: {
      title: '智能水电表',
      subtitle: '流动人口和出租房屋管理平台-寓信',
      opacityMenu: true
    }
  }, {
    name: 'deviceAccess',
    path: '/device/access',
    component: () => import('../views/device'),
    meta: {
      title: '智能门禁',
      subtitle: '流动人口和出租房屋管理平台-寓信',
      opacityMenu: true
    }
  }, {
    name: 'deviceGate',
    path: '/device/gate',
    component: () => import('../views/device'),
    meta: {
      title: '车辆/人行道闸',
      subtitle: '流动人口和出租房屋管理平台-寓信',
      opacityMenu: true
    }
  }, {
    name: 'deviceAlarm',
    path: '/device/alarm',
    component: () => import('../views/device'),
    meta: {
      title: '烟雾/燃气报警器',
      subtitle: '流动人口和出租房屋管理平台-寓信',
      opacityMenu: true
    }
  }, {
    name: 'apartment',
    path: '/product/apartment',
    component: () => import('../views/ProductApartment'),
    meta: {
      title: '智慧公寓管理系统',
      subtitle: '智慧公寓资产管理平台-寓信',
      opacityMenu: true
    }
  }, {
    name: 'wisdomBI',
    path: '/product/wisdomBI',
    component: () => import('../views/ProductBI'),
    meta: {
      title: '智慧BI数据分析系统',
      subtitle: '智慧BI数据分析系统-寓信',
      opacityMenu: true
    }
  }, {
    name: 'ProductRent',
    path: '/product/rent',
    component: () => import('../views/ProductRent'),
    meta: {
      title: '流动人口和出租房屋管理平台',
      subtitle: '寓信长租公寓管理系统_智慧公寓资产管理平台-寓信',
      opacityMenu: true
    }
  }, {
    name: 'HousingSupply',
    path: '/product/housingSupply',
    component: () => import('../views/HousingSupply'),
    meta: {
      title: '住房供应链平台',
      subtitle: '住房供应链平台-寓信',
      opacityMenu: true
    }
  }, {
    name: 'solution',
    path: '/solution',
    component: () => import('../views/Solution'),
    meta: {
      title: '解决方案',
      opacityMenu: true
    }
  }, {
    name: 'solutionDetails',
    path: '/solutionDetails/:name',
    component: () => import('../views/SolutionDetails'),
    meta: {
      title: '解决方案详情',
      opacityMenu: true
    }
  }, {
    name: 'plan',
    path: '/plan',
    component: () => import('../views/plan'),
    meta: {
      title: '信创方案',
      subtitle: '寓信长租公寓管理系统_智慧公寓资产管理平台-寓信',
      opacityMenu: true
    }
  },
  //{
  //  name: 'news',
  //  path: '/news',
  //  component: () => import('../views/NewsCenter'),
  //  meta: {
  //    title: '新闻中心',
  //    opacityMenu: true
  //  }
  //},
  {
    name: 'about',
    path: '/about',
    component: () => import('../views/AboutUs'),
    meta: {
      title: '关于我们',
      subtitle: '关于寓信科技-寓信',
      opacityMenu: true
    }
  }, {
    name: 'details',
    path: '/details/:id',
    component: () => import('../views/NewsDetails'),
    meta: {
      title: '新闻详情',
      opacityMenu: false
    }
  }
]